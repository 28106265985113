import React from 'react'


const Home = () => { 
    
    return (
        <div>
        <h1> this is my home page</h1>

        </div>
    )
}

export default Home