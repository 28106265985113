import React from 'react'


const Errors = () => { 
    
    return (
        <div>
<h1> Sorry! The page error 404</h1>

        </div>
    )
}

export default Errors