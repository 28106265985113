import React , {useState, useEffect}from 'react'
import axios, { AxiosError } from 'axios'
import { eventWrapper } from '@testing-library/user-event/dist/utils'
import { set } from 'react-hook-form'

const movieURL = 'https://www.themoviedb.org/movie?api_key='
const apiKey ='b36fa6255a7643db6801c94e69fa5d2c'

const movietitleURL =`https://www.themoviedb.org/movie?api_key=${apiKey}&query=`


const Movies = () => { 
const [movies,setMovies]    = useState([])
const [movieTitle, setMovieTitle] = useState('')

const popularMovies = async ()  =>{
    const response = await axios.get(`${movieURL}${apiKey}`)
        console.log(response.data.result)
        setMovies(response.data.results)
    
}


const searchTitle = async(searchTitle) => {    
    const response = await axios.get(`${movietitleURL}${searchTitle}`)
    setMovies(response.data.results)
}

const handleChange = (event) =>{
    setMovieTitle(event.target.value)

}

const handleSearch = (e) => {
e.preventDefault(); 
searchTitle(movieTitle)

}

useEffect(() => {
    popularMovies()
}, [] ); 


    return (
        <div className="container" style ={{marginTop:"50px"}}>
            <form>
                <div className="input-group mb-3">
                        <input type="text" name="searchTitle" onChange={handleChange} values={movieTitle}
                        className="form-control" placeholder="search movies by title" />
                        <span>
                            <button className="btn btn-primary" type="submit" onClick={handleSearch}>Search</button>
                        </span>

                </div>
            </form>
            <div className="row row-cols-1 row-cols-md-3 g-4">           
                {movies.map((movielist) => {
                    return (
                        <div className="col" key={movielist.id}>
                        <div className="card h-100">
                            <img src={'https://image.tmdb.org/t/p/w300/' + movielist.poster_path} className="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">{movielist.original_title}</h5>
                                <p className="card-text">{movielist.overview}</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Release Date: {movielist.release_date}</small>
                            </div>
                        </div>
                        </div>
                    )
                })}
            </div>    
</div>
    )
}

export default Movies