import React from 'react'
import {Link, Outlet} from 'react-router-dom'; 


const ContactNumber = () => { 
    
    return (
        <div>
            <Link to="/contact/contacnumnber"> contact number</Link>
            <Outlet/>

        </div>
    )
}

export default ContactNumber